import React, {createContext, useReducer} from 'react';

const initialState = {
		theme: 'dark',
		authUser: undefined,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch(action.type) {
			case 'TOGGLE_THEME':
				const newTheme = state.theme === 'light' ? 'dark' : 'light'
				return {...state, theme: newTheme}
			case 'SET_AUTHUSER':
				return {...state, authUser: action.value}
			
			default:
				return state;
		};
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }