import { store } from "../services/store";
import { useContext, useState, useRef } from "react";
import { Container, Button, Form, Col, FloatingLabel } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { addMat } from "../services/firebase";
import { useGeolocated } from "react-geolocated";

const Mat = () => {
    const globalStore = useContext(store);
    const navigate = useNavigate();

    const formRef = useRef(null);
    const [tagShop, setTagShop] = useState([]);
    const [inputFoodExpence, setInputFoodExpence]  = useState({
        Datum: (new Date().toISOString().substring(0, 10)),
        Butik: "",
        Plats: ""
    });

    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    const availableShops = ["ICA", "COOP", "Willys", "Ö&B", "Bordershop", "Systembolaget", "Fiskbilen", "Övrigt"];


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setInputFoodExpence(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const today = new Date();
        let addFoodObj = inputFoodExpence;
        addFoodObj.Butik = tagShop.toString();
        addFoodObj.Created = today.toISOString();
        addFoodObj.CreatedBy = globalStore.state.authUser.email;

        if (isGeolocationAvailable && isGeolocationEnabled) {
            const geoloc = {
                latitude: coords.latitude,
                longitude: coords.longitude,
                altitude: coords.altitude,
                accuracy: coords.accuracy,
                heading: coords.heading,
                speed: coords.speed
            }
            addFoodObj.Geolocation = geoloc;
        }
        addMat(addFoodObj).then(() => {
            navigate('/home');
        });
    }

    return (
        <Container className="contentArea">
            <h1>Mat</h1>
            <Form onSubmit={handleSubmit} ref={formRef}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Datum"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="date"
                        name="Datum"
                        value={inputFoodExpence.Datum}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Betalt belopp"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="Summa"
                        value={inputFoodExpence.Summa}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Plats (ort)"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="text"
                        name="Plats"
                        value={inputFoodExpence.Plats}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <Multiselect
                    placeholder="Butik"
                    options={availableShops}
                    isObject={false}
                    singleSelect={true}
                    selectedValues={tagShop}
                    onSelect={(list, item) => {
                        setTagShop(list);
                    }}
                    onRemove={(list, item) => {
                        setTagShop(list);
                    }}
                />
 
                <div className="col-md-12 text-right" style={{height: "20px", margin: "30px 0"}}>
                    <Button variant="primary" type="submit" className="float-end submitBtn">
                        Spara
                    </Button>
                </div>

            </Form>                        
        </Container>
    );
};

export default Mat;
