import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth, signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
}

const app = initializeApp(config);
export const firestoreDB = getFirestore(app);

export function logout() {
    const auth = getAuth();
    signOut(auth);
}

export async function signInWithGoogle() {
    const auth = getAuth();
    const googleProvider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    return user;
};

export async function addMat(matObj) {
    const docRef = await addDoc(collection(firestoreDB, "Mat"), matObj);
    return docRef;
}

export async function addDrivmedel(drivmedelObj) {
    const docRef = await addDoc(collection(firestoreDB, "Drivmedel"), drivmedelObj);
    return docRef;
}
