import { Button, Container, Card } from "react-bootstrap";
import { useContext } from "react";
import { store } from "../services/store";
import { signInWithGoogle } from "../services/firebase";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const globalStore = useContext(store);
    const navigate = useNavigate();

    const renderLoggedinUser = () => {
        if(globalStore.state.authUser !== undefined) {
            return (
                <h2>Inloggad som {globalStore.state.authUser.email}</h2>
            );
        } else {
            return (
                <h2>Logga in</h2>
            );
        }
    }

    return (
        <Container>
            <Card style={{ padding: "20px", margin: "80px" }}>
                <Card.Title>{renderLoggedinUser()}</Card.Title>
                <Button 
                    variant="primary" 
                    className="loginGoogleBtn" 
                    onClick={() => {
                        signInWithGoogle().then(usr => {
                            navigate('/home');
                        });
                    }}>
                    Logga in med Google
                </Button>
            </Card>
        </Container>
    );
};

export default Login;
