/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { store } from "../services/store";
import { logout } from "../services/firebase";
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Layout = () => {
	const globalStore = useContext(store);
    const { dispatch } = globalStore;
    const navigate = useNavigate();

	useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
				const action = { type: 'SET_AUTHUSER', value: user };
				dispatch(action);
				navigate('/home');
			}
        });
	}, []);

	const doLogout = () => {
		logout();
		const action = { type: 'SET_AUTHUSER', value: undefined };
		dispatch(action);
		navigate('/');
	}

	const renderLoginNav = () => {
		if(globalStore.state.authUser) {
			const logoutTitle = "Logga ut " + globalStore.state.authUser.email;
			return (
				<>
					<Navbar.Text onClick={doLogout} style={{cursor: "pointer"}}>
						{logoutTitle}
					</Navbar.Text>
				</>
			);
		} else {
			return (
				<Nav.Link as={Link} to="/">
					Logga in
				</Nav.Link>
			);
		}
	}

	return (
		<>
			<Navbar
				collapseOnSelect
				expand="lg"
				bg={globalStore.state.theme}
				variant={globalStore.state.theme}
			>
				<Container>
					<Navbar.Brand as={Link} to={globalStore.state.authUser ? "/home" : "/"}>
						Utgifter
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav>
							{renderLoginNav()}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Outlet />
		</>
	);
};

export default Layout;
