import { Button, Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();

	return (
        <Container className="contentArea">
            <h1>Registrera utgift</h1>
            <div className="d-grid gap-5">
                <Button 
                    variant="outline-primary"
                    onClick={() => {
                        navigate('/mat');
                    }}>
                    Mat
                </Button>
                <Button 
                    variant="outline-primary" 
                    onClick={() => {
                        navigate('/drivmedel');
                    }}>
                    Drivmedel
                </Button>
            </div>
        </Container>
    );
};

export default Home;
