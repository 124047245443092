import { store } from "../services/store";
import { useContext, useState, useRef } from "react";
import { Container, Button, Form, Col, FloatingLabel } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { addDrivmedel } from "../services/firebase";
import { useGeolocated } from "react-geolocated";

const Drivmedel = () => {
    const globalStore = useContext(store);
    const navigate = useNavigate();

    const formRef = useRef(null);
    const [tagShop, setTagShop] = useState([]);
    const [tagCar, setTagCar] = useState([]);
    const [inputExpence, setInputExpence]  = useState({
        Datum: (new Date().toISOString().substring(0, 10)),
        Butik: "",
        Plats: ""
    });

    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    const availableShops = ["Din-X", "InGo", "Preem", "St1", "Circle K", "OKQ8", "Övrigt"];
    const availableCar = ["MCZ290", "BRF870", "Gräsklippare"];


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value ? event.target.value : "";
        setInputExpence(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const today = new Date();
        let addObj = inputExpence;
        addObj.Butik = tagShop.toString();
        addObj.Bil = tagCar.toString();
        addObj.Created = today.toISOString();
        addObj.CreatedBy = globalStore.state.authUser.email;

        if (isGeolocationAvailable && isGeolocationEnabled) {
            const geoloc = {
                latitude: coords.latitude,
                longitude: coords.longitude,
                altitude: coords.altitude,
                accuracy: coords.accuracy,
                heading: coords.heading,
                speed: coords.speed
            }
            addObj.Geolocation = geoloc;
        }
        addDrivmedel(addObj).then(() => {
            navigate('/home');
        });
    }

    return (
        <Container className="contentArea">
            <h1>Drivmedel</h1>
            <Form onSubmit={handleSubmit} ref={formRef}>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Datum"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="date"
                        name="Datum"
                        value={inputExpence.Datum}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Antal liter"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="Liter"
                        value={inputExpence.Liter}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Betalt belopp"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="Summa"
                        value={inputExpence.Summa}
                        onChange={handleChange}
                    />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Plats (ort)"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="text"
                        name="Plats"
                        value={inputExpence.Plats}
                        onChange={handleChange}
                    />
                </FloatingLabel>

                <div style={{margin: "20px 0"}}>
                    <Multiselect
                        placeholder="Butik"
                        options={availableShops}
                        isObject={false}
                        singleSelect={true}
                        selectedValues={tagShop}
                        onSelect={(list, item) => {
                            setTagShop(list);
                        }}
                        onRemove={(list, item) => {
                            setTagShop(list);
                        }}
                        style={{margin: "20px 0"}}
                    />
                </div>
                <div style={{margin: "20px 0"}}>
                    <Multiselect
                        placeholder="Bil"
                        options={availableCar}
                        isObject={false}
                        singleSelect={true}
                        selectedValues={tagCar}
                        onSelect={(list, item) => {
                            setTagCar(list);
                        }}
                        onRemove={(list, item) => {
                            setTagCar(list);
                        }}
                        
                    />
                </div>
                
                <FloatingLabel
                    controlId="floatingInput"
                    label="Mätarställning"
                    as={Col}
                    style={{margin: "20px 0"}}
                >
                    <Form.Control
                        type="number"
                        name="Mil"
                        value={inputExpence.Mil}
                        onChange={handleChange}
                    />
                </FloatingLabel>



                <div className="col-md-12 text-right" style={{height: "20px", margin: "30px 0"}}>
                    <Button variant="primary" type="submit" className="float-end submitBtn">
                        Spara
                    </Button>
                </div>

            </Form>                        
        </Container>
    );
};

export default Drivmedel;
