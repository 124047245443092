import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
import Mat from "./pages/Mat";
import Drivmedel from "./pages/Drivmedel";
import {StateProvider} from "./services/store";

import "bootstrap/dist/css/bootstrap.min.css";
import './Styles/index.css';
import "bootstrap-icons/font/bootstrap-icons.css"

function App() {
  return (
    <StateProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/mat" element={<Mat />} />
            <Route path="/drivmedel" element={<Drivmedel />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
