import { Container } from "react-bootstrap";

const NoPage = () => {
    return (
        <Container>
            <div className="pageHeader">
                Page not found <span className="badge bg-secondary">404</span>
            </div>
        </Container>
    );
};
  
export default NoPage;